//@import "shortcuts_local.scss";
//@import "site.scss";

a{
	&:hover, &:focus{}
}
a.button.faces-toggle-button {
    margin-bottom: 2rem;
	width: 100px;
	max-width: 100px;
	height: auto;
}
ul.bread_crumbs{
	li{}
	a{
		&:hover, &:focus{}
	}
}


.main_area{
  margin-top: 35px; order: 2;
  @include media-breakpoint-up(lg) {
	width:600px; float:right; margin:35px 51px 0 0;
  }
	&.full {
	  width:auto; float:none; margin-right:0;
	}
}
.main{
	a{color:$brand-secondary; @extend .sans;
		&:hover, &:focus{text-decoration:underline;}

		&.forgot{@extend .sans;text-decoration:underline; margin-top:12px; display:inline-block;
			&:hover, &:focus{text-decoration:none;}
		}
	}
	span[style*="color:#44156e;"] {
		color: $brand-primary !important;
	}
	em{
		a{font-style:italic;}
	}
	h1, h2, h3, h4, h5, h6{@extend .headline; text-transform:uppercase; }
	h1{@include rem(4); color:$brand-primary;margin-bottom:13px; @extend .headline-bold; font-weight: normal;
		&.news_detail{color: #575656; @include rem(2.8); @extend .sans-bold; line-height:1.2; margin-bottom:12px; }
	}
	h2{@include rem(3); color:$brand-tertiary; margin:-5px 0 18px;}
	h3{@include rem(2.5); color:$brand-primary; margin:-2px 0 20px;}
	h4{@include rem(2); color:#999; margin:28px 0 12px;}
	h5, h6{@include rem(1.8); color:#000; margin:34px 0 13px;}

	p{@include rem(1.6);color:#575656; @extend .sans; line-height:1.7; margin:0 10px 24px 0;
	  	&.faces-list-link {margin-bottom: 0px;}
		&.featured{@include rem(3);@extend .headline; color:#575656; line-height:1.18; margin:0 0 26px 0; }
		&.title{@include rem(2);@extend .headline-bold; color:$brand-tertiary; margin-bottom:10px; line-height: 1.2 }
		+ul{margin-top:-14px;}
		&.date_cat{margin-bottom:34px;
			time{font-style:italic;}
			a{@extend .sans; text-decoration:underline;
				&:hover, &:focus{text-decoration:none;}
			}
		}
		&.showing{ @extend .sans; text-transform:uppercase; @include rem(2); color:#999; margin:0px 0 35px;}

	}

	ul{ margin:0 0 26px 17px;
		li{background:  url(#{$layout}li.png) 0 7px no-repeat;@include rem(1.6); line-height:1.3; color:#575656; @extend .sans; padding:0 0 0 15px; margin-bottom:9px;}
	}

	ol{margin:0 0 26px 34px;
		li{@include rem(1.4); line-height:1.3; color:#575656; margin-bottom:8px; @extend .sans;}
	}

	blockquote{border-left:1px solid #B5B5B5; margin:11px 0 53px 4px; padding:0 0 0 30px;
		p{@extend .headline; color:#575656; @include rem(2.3); line-height:1.4; padding:5px 0 0; margin-right:54px;}
	}

	.half{
	  display:inline-block; vertical-align:top;
	  @include media-breakpoint-up(lg) {
		width:280px; margin-right:38px;
	  }
	}
	.last{margin-right:0;}
	img{ padding:5px; display:inline-block;
		&.left, &[align='left']{float:left; margin:0 22px 10px 0px; }
		&.right, &[align='right']{float:right; margin:0  0 10px 22px;}
	}
	span.image_wrap{padding:5px; display:block;
	  @include media-breakpoint-up(lg) {
		display:inline-block;
	  }
		img{padding:none; background:#ECEEF1; padding:1px; display:block;
		  @include media-breakpoint-down(sm) {
			max-width: 300px;
			height: 100% !important;
		  }
			&.left, &.right{float:none; margin:0;}

		}
		&.left, &[align='left']{
		  @include media-breakpoint-up(lg) {
			float: left;
			margin: 0 22px 10px 0px;
		  }
		}
		&.right, &[align='right']{
		  img {
			float: none !important;
			@include media-breakpoint-up(lg) {
			  float: right !important;
			}
		  }
		  @include media-breakpoint-up(lg) {
			float: right;
			margin: 0 0 10px 22px;
		  }
		}
		&+h2{margin-top:46px;}
	}
	.partner-images{
		margin-bottom: 15px;
	}
	.partner-image{width:30%; margin-right:3%; display:inline-block; vertical-align:middle; margin-bottom:10px; text-align:center;
		

		span.image_wrap{max-width:100%;
			img {
				max-width: 100%;
			}
		}
		

		a {
			font-size: 2em;
		}
	}

	.form_row{@extend .clear_fix; margin-bottom:27px;
		.half{
		  @include media-breakpoint-up(lg) {
			width:44%; margin-right:5%;
		  }
		}
		.third{
		  @include media-breakpoint-up(lg) {
			width:30%; margin-right:3%;
		  }
		}
		.half,.third{ display:inline-block; vertical-align:top;}
	}
	label, .label{@extend .headline-bold; color:#333; @include rem(2); display:block; margin-bottom:8px;
		&[for]{cursor:pointer;}
	}
	input{
		&[type="text"],&[type="email"],&[type="number"],&[type="url"],&[type="password"]{@extend %br5; border:1px solid #BFC0C2; border-bottom-color:#E2E3E5; width:212px; height:26px; padding:3px;}
		&[type="radio"]{}
		&[type="checkbox"]{}
	}
	.error{
		input{
			&[type="text"],&[type="email"],&[type="number"],&[type="url"],&[type="password"]{border:2px solid #cb0000;}
		}
		label{color:#f3352f;}
		.message{color:#999; @extend .sans;}
	}
	textarea{width:565px; height:122px; padding:14px;@extend %br5;border:1px solid #BFC0C2; border-bottom-color:#E2E3E5; resize:none;}

	ul{
		&.radio, &.checkbox{margin:0;
			li{background: none; padding:0; }
			label{display:inline; @extend .sans;}
		}
	}

	.message_box{border: 2px solid #e9e9eb; @include br(7px); background-color: #fff;  @include rem(1.2);
		&.warning{
			background:#FFF url(#{$sub}warning_icon.png) 11px 6px no-repeat; padding:18px 0 15px 53px; color: #cb0000;
		}
	}


	table{border:5px solid #FFF; border-collapse:collapse; margin:36px 0; width:100% !important;
	  @include media-breakpoint-down(lg) {
		margin-top: 0;
	  }
		&[align="right"] { margin-left:15px; margin-bottom: 10px; }
		&[align="left"] { margin-right:15px; margin-bottom: 10px; }
	}
	thead{
		th{background-color: $brand-primary; color:#FFF; @include rem(1.8); border:1px solid #FFF; border-width:0 1px 5px 0;padding:17px 10px 13px;}
	}
	tbody{
	  p {
		@include media-breakpoint-down(lg) {
		  margin: 0;
		}
	  }
		tr{
			&:nth-child(odd){
				td{background-color: #DFDEDE;}
			}
			&.odd{
				td{background-color: #DFDEDE;}
			}
		}
		td{
		  background:#FFF;border-right:1px solid #FFF; color:#000; @include rem(1.6); padding:16px 11px 13px; text-align: left!important;
		  br {
			display: none;

			@include media-breakpoint-up(lg) {
			  display: block;
			}
		  }
		}
	}

  .table_wrapper {
	position: relative;
  }

  .table_wrapper_overflow {
	position: relative;

	border: 1px solid #ccc;

	&:after {
	  background:
		linear-gradient(
			to right,
			rgba(#000, 0),
			rgba(#000, 0.1)
		);

	  width: 20px;
	  height: 100%;

	  position: absolute;
	  top: 0;
	  right: 0;

	  content: "";

	  @include media-breakpoint-up(lg) {
		content: none;
	  }
	}
  }

  .table_wrapper_inner {
	overflow-y: hidden;
	-webkit-overflow-scrolling: touch;
	-ms-overflow-style: -ms-autohiding-scrollbar;
  }


	.button, a.button {
		@extend .headline-bold;

		&:hover, &:focus {
			text-decoration: none;
		}
	}

}

.ff_composer{
	.form_row{@extend .clear_fix; margin-bottom:27px;
		.half{width:44%; margin-right:5%;}
		.third{width:30%; margin-right:3%;}
		.half,.third{ display:inline-block; vertical-align:top;}
	}
	label, .label{@extend .headline-bold; color:#333; @include rem(2); display:block; margin-bottom:8px;
		&[for]{cursor:pointer;}
	}
	input{
		box-sizing: content-box;
		-moz-box-sizing: content-box;
		-ms-box-sizing: content-box;
		-webkit-box-sizing: content-box;
		&[type="text"],&[type="email"],&[type="number"],&[type="url"],&[type="password"]{@extend %br5; border:1px solid #BFC0C2; border-bottom-color:#E2E3E5; width:212px; height:26px; padding:3px;}
		&[type="radio"]{}
		&[type="checkbox"]{}
	}
	.error{
		input{
			&[type="text"],&[type="email"],&[type="number"],&[type="url"],&[type="password"]{border:2px solid #cb0000;}
		}
		label{color:#f3352f;}
		.message{color:#999; @extend .sans;}
	}
	textarea{width:565px; height:122px; padding:14px;@extend %br5;border:1px solid #BFC0C2; border-bottom-color:#E2E3E5; resize:none;}

	ul{margin:0;
		li{background: none; padding:0; }
		label{display:inline; @extend .sans;}
	}

	input[type="submit"] {
		font-family: 'TradeGothicLT-BoldCondTwenty', sans-serif;
    	-webkit-font-smoothing: antialiased;
    	padding: 8px 26px 6px;
    	color: #FFF !important;
	    margin-right: 22px;
	    border: 1px solid #853a18;
	    background-color: #c05423;
	    border-radius: 6px;
	    font-size: 25px;
	    font-size: 2.5rem;
	    line-height: 1;
	    display: inline-block;
	    vertical-align: middle;
	    text-transform: uppercase;
		cursor:pointer;

		&:hover, &:focus{
			background-color: #c05423; 
		    //background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJod…IwIiB3aWR0aD0iMTAwIiBoZWlnaHQ9IjEwMCIgZmlsbD0idXJsKCNoYXQwKSIgLz4KPC9zdmc+);
		    background-image: -moz-linear-gradient(top, #c05423 0%, #95411b 100%);
		    background-image: -o-linear-gradient(top, #c05423 0%, #95411b 100%);
		    background-image: -webkit-linear-gradient(top, #c05423 0%, #95411b 100%);
		    background-image: linear-gradient(top, #c05423 0%, #95411b 100%);
		    -webkit-box-shadow: inset 2px 2px 4px rgba(255, 255, 255, 0.5);
		    -moz-box-shadow: inset 2px 2px 4px rgba(255, 255, 255, 0.5);
		    box-shadow: inset 2px 2px 4px rgba(255, 255, 255, 0.5);
		    text-decoration: none;

		}
	}
}

ul.news_list{
  margin-left:0;

  @include media-breakpoint-up(lg) {
	margin-right:-56px;
  }
	p.date_cat{margin-bottom:19px;}
	li{
	  padding:0 0 1px; margin-bottom:32px; border-bottom: 1px dotted gray; background: none;

	  @include media-breakpoint-up(lg) {
		background:url(#{$sub}news_divide.png) no-repeat 50% 100%;
	  }
	}
	h2, h2 a{@extend .sans; @include rem(2); line-height: 1; font-weight: normal;}
	h2{
	  margin-bottom:4px;

	  @include media-breakpoint-up(lg) {
		margin-right:100px;
	  }
	}
	h2 a{color:$brand-primary;
		&:hover, &:focus{color:darken($brand-primary, 13.7%);}
	}
}



.side_box{margin-bottom:25px;
	.side_col & {
		a:hover {
			text-decoration: none;
		}
	}
}

.mobile_sub_menu {
  display: none;
  background: #fff;
  padding: 20px;

  &.active {
	display: block;
  }

  @include media-breakpoint-up(lg) {
	display: block;
	background: transparent;
	padding: 0;
  }
}

.mobile_sub_menu_label {
  color:$brand-primary; @include rem(2); @extend .headline-bold; text-transform:uppercase; padding: 10px; background: #fff;
  border: 4px solid #c05423;
  position: relative;

  @include media-breakpoint-up(lg) {
	display: none;
  }

  &:after {
	position: absolute;
	right: 15px;
	top: 15px;
	width: 0;
	height: 0;
	border-left: 10px solid transparent;
	border-right: 10px solid transparent;
	border-top: 10px solid $brand-primary;
	content: "";
	display: block;
  }
}

.side_col{
  	  order: 1;
  margin-top: 20px;
  @include media-breakpoint-up(lg) {
	width:265px; float:left; background:  url(#{$sub}side_top3.png) 0 0 no-repeat; margin: 0;
  }
	.bottom{

	  @include media-breakpoint-up(lg) {
		background:  url(#{$sub}side_bottom.png) 1px 100% no-repeat; padding:21px 26px 80px;  min-height:390px;
	  }
	}
	nav{
	  @include media-breakpoint-up(lg) {
		margin-bottom:49px;
	  }
		ul{
			li{
			  border-bottom: 1px dotted gray;
			  @include media-breakpoint-up(lg) {
				background: url(#{$sub}side_dots.png) no-repeat 50% 100%;
			  }
				a{color:$brand-primary; @include rem(2); @extend .headline-bold; text-transform:uppercase; display:block; padding:7px 8px;line-height:1.2;
					&:hover, &:focus{color:darken($brand-primary, 7.5%); text-decoration: none;}
				}
				&.here, &.parent-here{ background: $brand-primary url(#{$sub}side_dots.png) no-repeat 50% 100%;
					a{color:#FFF; background: url(#{$sub}side_li.png) no-repeat 193px 50%; }
					li{background: none;
						a{color:$brand-primary; @include rem(1.4); @extend .sans; background:  url(#{$sub}side_li_li.png) 0 12px no-repeat; text-transform:none; padding:5px 8px 4px;
							&:focus, &:hover{color:$brand-secondary;}
						}
						&.here{
							a{color: $brand-secondary;}
						}
						&.here, &.parent-here{

							li{
								a{@include rem(1.2);font-weight:normal; color: $brand-primary; background:  url(#{$sub}side_li_li_li.png) 0 10px no-repeat; padding:5px 32px 6px 12px;}
								&.here{
									a{color: $brand-secondary;}
								}
							}
						}
					}
				}


			}
			ul{background-color: #EAEDF0; padding:5px 19px 1px;
				ul{padding:1px 12px;}
			}
		}
	}
	.divide{background: url(#{$sub}side_dots.png) no-repeat 50% 100%; height:1px; margin:2px 0;}
	.link_portlet{background-color: #EAEDF0;padding: 5px 19px 1px;
		&:first-child{margin-top:-6px;}
		&.last{margin-bottom:37px;}

		h2, h2 button{
			margin:-5px -19px 0; 
			color:#FFF;
	 		//background:$brand-primary url(#{$sub}side_li.png) no-repeat 193px 12px; 
	 		background:$brand-primary;
		 	@include rem(2); 
		 	@extend .headline; 
		 	text-transform:uppercase; 
		 	display:block; 
		 	padding:9px 29px 7px 8px;
		 	line-height:1;
		 }
		 .h2btn{
			padding:0;
		}
		 h2 button{
			border:none;
			margin:0;
			cursor:pointer;
			display: block;
			font-weight: bold;
			width:100%;
			padding-right:10px;
			text-align: left;
			.icon-chevron-down{
				float:right;
				margin-top: -2px;
				transition:.25s all;
			}

			&.active{
				.icon-chevron-down{
					transform:rotate(180deg);
					margin-top:0; 
				}
			}
			&:hover, &:focus{
				background:darken($brand-primary, 10%);
			} 
		 }
		ul{
			margin:12px 0 -1px;
			display: none; 
		}
		li{background: none; margin-bottom:11px;
			a{color:$brand-primary; @include rem(1.4); @extend .sans; background:  url(#{$sub}side_li_li.png) 0 13px no-repeat; text-transform:none; padding:5px 8px 4px;
				&:focus, &:hover{color:$brand-secondary;}
			}
			&.here{
				a{font-weight:bold;}
			}
			&.here, &.parent-here{
				li{
					a{@include rem(1.2);font-weight:normal; background:  url(#{$sub}side_li_li_li.png) 0 10px no-repeat; padding:5px 32px 6px 12px;}
					&.here{
						a{font-weight:bold;}
					}
				}
			}
		}
	}

	a.member_bulletin{
		background: $brand-secondary url(#{$layout}main_sprite.png) no-repeat 15px -157px;@include rem(2.2); color:#FFF; @extend .headline-bold; text-transform:uppercase; display:block; padding:6px 8px 6px 40px; margin:19px 0 16px;line-height:1; @extend %br5;
		letter-spacing: -.05em;
		&:hover {
			text-decoration: none;
		}


	}

	.side_box{
		a.member_bulletin{
			@include rem(1.9); line-height: 24px;
		}
	}


	.content{padding:0 2px;}
	h3, h4, h5, h6{color:$brand-tertiary; @include rem(2); @extend .headline; text-transform:uppercase; margin-bottom:12px; }
	h3.alt{color:#749500;}
	h4{@include rem(1.8);}
	h5{@include rem(1.6);}
	h6{@include rem(1.4);}
	p{color:#666; @include rem(1.3); line-height:1.6;}

	a {
		color: $brand-secondary;

		&:hover, &:visited {
			text-decoration: underline;
		}
	}
}


.pagination{margin:39px 0 0 7px;
	ul{margin:0;
		li{background:none; padding:0; margin:0; display:inline-block; @include rem(1.4); margin-right:13px;}
	}
	a{color:#999;
		&:hover, &:focus{ color:$brand-tertiary;}
		&.active{color:$brand-primary;}
		&.prev, &.next{@include transition(all 0.5s);position:relative; top:-1px;color:#FFF; @include br(3px); @include rem(1.3); background: $brand-primary url(#{$sub}pagination_arrows.png) 10px 50% no-repeat;
			&:hover, &:focus{background-color:darken($brand-primary, 13.7%);  text-decoration:none;}
		}
		&.prev{ padding:7px 16px 6px 19px;}
		&.next{padding:7px 30px 6px 19px; background-position:108% 50%;}
	}
}

.people .img {
	margin:10px 0 20px;
	max-width:100%;
	span, img {
		max-width: 100%;
	}
}



.tabs{}
.theTabs{margin-bottom: -1px;
	a{
		&:hover, &:focus{}
		&.active{}
	}
}
.tabPane{display: none;}
.tabPane.activePanel{display:block; 	h3{color:#313131;}

}

.main span.image_wrap img {
 @include media-breakpoint-down(sm) {
	max-width: 300px;
	height: auto !important;
  }
}

.main-faces-list {
  h2 {
	margin-top: 25px;
  }
}

.main-faces-detail {
  @include media-breakpoint-up(md) {
	iframe {
	  width: 100%;
	  height: 300px;
	  margin-bottom: 25px;
	}
  }
}

.toggle-listing {
  display: flex;
}

.toggle-wrap.Patients {
  margin-right: 50px;
}

.toggle-wrap {
  width: 45%;
	@include media-breakpoint-down(sm){
		width: 100%;
	}

  .toggle {
	cursor: pointer;
  }
}

.toggle {
  .toggle-active & {
	text-decoration: underline;
  }
}

.toggle-wrap.Patients.toggle-active .toggle-details,
.toggle-wrap.Providers.toggle-active .toggle-details {
  display: block;
}

.toggle-wrap.Providers .toggle-details,
.toggle-wrap.Patients .toggle-details {
  display: none;
}

.main span.image_wrap img.faces-image {
  max-width: 150px;
  height: auto;
}

.faces-wrap {
  margin-bottom: 15px;
  border-bottom: 1px solid #bfc0c2;
  padding-bottom: 20px;
}

