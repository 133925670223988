@mixin reset{
	/*Start Rest*/
	html, body, div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre,abbr, address, cite, code,del, dfn, em, img, ins, kbd, q, samp,small, strong, var,b, i,dl, dt, dd, ol, ul, li,fieldset, form, label, legend,table, caption, tbody, tfoot, thead, tr, th, td,article, aside, canvas, details, figcaption, figure, footer, header, hgroup, menu, nav, section, summary,time, mark, audio, video {margin:0;padding:0; border:0; outline:0;font-size:100%;vertical-align:baseline;background:transparent; -moz-box-sizing: border-box; -webkit-box-sizing: border-box; box-sizing: border-box;}
	
	body { line-height:1; font-size: 62.5%;
		&.largeFont { font-size: 70.5%; }
		&.medFont { font-size: 67.5%; }
		&.smallFont { font-size: 62.5%; }
	}
	
	article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section {  display:block;}
	ul {list-style: none;}
	td, th{vertical-align:top;}
	a{text-decoration:none;}
	table {border-collapse: separate;border-spacing: 0;}
	caption, th, td {text-align: left;font-weight: normal;}
	blockquote:before, blockquote:after,
	q:before, q:after {content: "";}
	blockquote, q {quotes: "" "";}
	strong,b{font-weight:bold;}
	em, i{font-style:italic}
	ins { background-color:#ff9;color:#000;text-decoration:none;}
	
	/* change colours to suit your needs */
	mark {background-color:#ff9;color:#000; font-style:italic;font-weight:bold;}
	del { text-decoration: line-through;}
	abbr[title], dfn[title] { border-bottom:1px dotted; cursor:help;}
	table { border-collapse:collapse; border-spacing:0;}
	
	/* change border colour to suit your needs */
	hr { display:block; height:1px; border:0; border-top:1px solid #cccccc; margin:1em 0; padding:0;}
	input, select {vertical-align:middle; outline: none;}
	textarea{outline:none;}
	/*End Reset*/
	
	.skip{position:absolute; left:-9999px; width:1px; height:1px; overflow:hidden; width:600px; color:#FFF;
		&:focus, &:active{position:static; width:auto; height:auto; left:0px ; margin-right:10px;}
	}
	.noreveal{
		&:focus, &:active{position:absolute; left:-9999px; width:1px; height:1px; overflow:hidden; width:600px; color:#FFF;}
	}
	.access{position:absolute; left:-9999px; width:1px; height:1px; overflow:hidden;}
	.forPrint{display: none;}	
}

@mixin hide_text {
	text-indent: 100%;
	white-space: nowrap;
	overflow: hidden;
}

@mixin access{
	text-indent: 100%;
	white-space: nowrap;
	overflow: hidden;
}

@mixin bp($left, $top){
	background-position: $left $top;
}

@mixin clearfix{
	.clear_fix:after{ content: ""; display: block; height: 0; clear: both;}
	.clear_fix{min-height: 1%;}
}


@mixin box-shadow ($params...){
  -webkit-box-shadow: $params;
     -moz-box-shadow: $params;
          box-shadow: $params;
}


@mixin br($radius){
  	border-radius: $radius;
}


@mixin gv($start, $end){
  	background: $start; 
  	background-image: -moz-linear-gradient(top, $start, $end);
  	background-image: -webkit-gradient(linear, left top, left bottom, from($start), to($end)); 
  	background-image: -webkit-linear-gradient(top, $start, $end); 
  	background-image: -o-linear-gradient(top, $start, $end); 
  	background-image: linear-gradient(top, $start, $end);
  	background-image: -ms-linear-gradient(top, $start, $end); 
  	filter:progid:DXImageTransform.Microsoft.gradient(startColorstr='$start', endColorstr='$end');
}


@mixin gh($start, $end){
  	background: ($start + $end) / 2; 
  	background-image: -moz-linear-gradient(left, $start, $end); 
    background-image: -webkit-gradient(linear, left center, right center, from($start), to($end)); 
  	background-image: -webkit-linear-gradient(left center, right center, from($start), to($end)); 
  	background-image: -o-linear-gradient(left, $start, $end); 
  	background-image: linear-gradient(left, $start, $end); 
  	background-image: -ms-linear-gradient(left, $start, $end); 
}


@mixin rotate ($deg) {
  -moz-transform: rotate(#{$deg}deg);
  -o-transform: rotate(#{$deg}deg);
  -ms-transform: rotate(#{$deg}deg);
  -webkit-transform: rotate(#{$deg}deg);
}

@mixin scale ($size) {
	-moz-transform: scale(#{$size});
	-o-transform: scale(#{$size});
	-ms-transform: scale(#{$size});
	-webkit-transform: scale(#{$size});
}

@mixin transition ($value...) {
  -moz-transition: $value;
  -o-transition: $value;
  -ms-transition: $value;
  -webkit-transition: $value;
  transition: $value;
}

@mixin filter ($value...){
	-moz-filter: $value;
	-o-filter: $value;
	-ms-filter: $value;
	-webkit-filter: $value;
	filter: $value;
}

@mixin rem($sizeValue: 1.6){
  font-size: ($sizeValue * 10) + px;
  font-size: $sizeValue + rem;
}

@mixin vendorize($property, $value) {
	-webkit-#{$property}: $value;
	-moz-#{$property}: $value;
	-ms-#{$property}: $value;
	-o-#{$property}: $value;
	#{$property}: $value;
}

/*
	$base_width: 960;
	$number_of_columns: 12;
	$gutter_size:10;
*/

@function c_width($base_width, $number_of_columns){
	@return ($base_width / $number_of_columns) * 1%;
}

@function column_width($number_of_cols, $base_width, $number_of_columns, $gutter_size){
	@return (((c_width($base_width, $number_of_columns) * $number_of_cols) - ($gutter_size * 2)) / $base_width) * 100;
}

@function gutter_width($offset, $gutter_size, $base_width, $number_of_columns){
	@if($offset == 0){
		@return ($gutter_size/$base_width) * 100%;
	} @else {
		@return (((c_width($base_width, $number_of_columns) * $offset) + $gutter_size) / $base_width) * 100;
	}
}



@mixin cols($number_of_cols, $offset_left:0, $offset_right:0, $bw:$base_width, $noc:$number_of_columns, $gs: $gutter_size){
	width:column_width($number_of_cols, $bw, $noc, $gs);
	margin-left:gutter_width($offset_left, $gs, $bw, $noc);
	margin-right:gutter_width($offset_right, $gs, $bw, $noc);
}

