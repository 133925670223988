#slider_wrap{
	box-shadow: none; background: none;
	padding: 0;
	margin-top: 20px;
	height: 340px;

  @include media-breakpoint-up(lg) {
	width: 920px;
	height: 370px;
	margin-top: 10px;
  }

  .bx-wrapper {
	height: 100%;
  }
	
	.content{width:100%; height:60%; position:absolute; right:0; bottom:0px; top: auto;
		background: #fff;
		padding: 20px 40px;

	  @include media-breakpoint-up(lg) {
		width:70%; height:38%;padding: 20px;
	  }
	}
	h2{color: $brand-secondary; @extend .headline; @include rem(2); line-height:1.1; text-transform:uppercase; margin-bottom:10px;
		a{color:$brand-secondary;}
	}
	p {
		margin-bottom: 10px; @include rem(1.6);
	}
	a.more, span.more{
		display:inline-block; margin-top:0;  @include rem(1.6);@extend .sans; 
		background: none; border: none; color: $brand-secondary; text-decoration: underline;
		padding: 0 20px 0 0; text-transform: none;
		background: url(../images/site/home/external.png) no-repeat 99% 2px;

		&:hover {
			text-decoration: none;
		}
	}
	.bx-wrapper .bx-pager, .bx-wrapper .bx-controls-auto{
		bottom:11px;
		width: auto;
		left: 15px;
	}
	.bx-wrapper .bx-pager.bx-default-pager a{
		@include br(0); 
		background:  lighten($slide-nav, 20%);
		border-radius: 100%;
		width:16px; height:16px; margin:0 4px;

		&.active, &:hover, &:focus{
			background: $slide-nav;
		}
	}
	.bx-wrapper .bx-pager{padding-top:0; margin-top:20px;}
}

.home{

	.half, .third{
		p{@include rem(1.6);}
		a{
			&.more{@include rem(1.6);
			}
		}
	}

}

iframe {

  @include media-breakpoint-down(sm) {
	width: 100%;
	height: 100%;
	min-height: 220px;
  }
}