%twitter_title{
    @extend .headline; 
    background-color: $brand-primary; 
    border-bottom:3px solid #ECEEF1; 
    color:#FFF; @include rem(2.2); 
    text-transform:uppercase; 
    padding:11px 21px 9px;
}

%twitter_contain{
    margin: auto;
    @extend .clear_fix;

    @include media-breakpoint-up(lg) {
        width: 940px;
    }
}

%twitter_btm{
    display: block;
    text-align: center;
    padding: 20px 0;
    background: #fff;
    border-top: 1px solid #dedede;
    margin-top: -15px;
}
%twitter_btn{
    display: inline-block;
    padding: 10px 35px 10px 20px; 
    @extend .headline-bold;
    color:$brand-secondary;
    @include rem(1.6);
    border: 2px solid #dedede;
    background: url(../images/site/home/external.png) no-repeat 89% 13px #fff;
    transition: all .125s;

    &:hover, &:focus {
        background-color: rgba(#dedede, .3);
        color: $brand-primary;
    }
}

%twitter_content{
    background: #fff;
        padding: 20px;
        @extend .clear_fix; 
}
.twitter-feed {
    margin-bottom: 20px;
    

    .contain {
        @extend %twitter_contain;
    }

    &__title {
        @extend %twitter_title;
    }

    &__content {
        @extend %twitter_content;
    }

    &__text{
        @include rem(1.5); 
        line-height:1.3; 
        color:#333; 
        margin:0 22px 17px 0;

        a{
            color:$brand-secondary;
            &:hover, &:focus{
                text-decoration:underline;
            }
        }
    }

    &__retweet {
        background: url(/assets/images/site/layout/retweet.png) no-repeat 0 -0px;
        padding-left: 20px;
        display: block;
    }

    &__meta {
        @include rem(1.4); 
        line-height:1.65; 
        color:#666; 
    }

    &__item {
        @extend .clear_fix;
        margin-bottom: 5px;
        padding-top: 20px;
        border-top: 1px solid #dedede;

        &:first-of-type {
            padding: 0;
            border: 0;
        }

        &__img {
            float: left;
            width:48px;
        }

        &__content {
            float: left;
            width: 90%;
            margin-left: 2%;

            strong {
                font-weight: bold;
                color: #333;
            }
        }
    }

    &__btm {
        @extend %twitter_btm;
    }

    &__btn {
        @extend %twitter_btn;
    }

}
.contain{
    @extend %twitter_contain;
    padding-right: 1.5rem;
    padding-left: 1.5rem;

    @include media-breakpoint-up(lg) {
        padding: 0;
    }
}

.hpc{
    margin-top:12px;
    margin-bottom: 36px;

    &--wp{

        @include media-breakpoint-up(lg) {
            width:300px;
            float:left;
            margin-right:20px;
        }
    }
    &--jobs{

        @include media-breakpoint-up(lg) {
            width:300px;
            float: left;
        }
    }
    &--higher-logic{

        @include media-breakpoint-up(lg) {
            float:left;
            width:300px;
            margin-right:20px;
        }
    }
    &__title{
        @extend %twitter_title;
        
    }

    
    &__content {
        background: #fff;
        padding: 20px 0px;
        @extend .clear_fix; 
    }
    
    &__item{

        padding:12px 20px;

        @include media-breakpoint-up(lg) {
            width:310px;
            float:left;
        }
        //background:url(/assets/images/site/sub/side_dots2.png) repeat-y 100% 0;
        // &:nth-child(even){ 
        //  background-position:0 0;
        //  margin-left: -1px;
        //  padding-left:30px;
        // }
        &--nf{
            width:100%;
            float:none;
            background:none;
            margin-left: 0 !important;
            padding-left:20px !important;

        }
        &__content{

            &--border{
                background:#FFF url(/assets/images/site/sub/side_dots.png) repeat-x 0 100%;
                border-bottom: 1px solid #FFF;
                position: relative;
                margin-bottom: 0px;
                .hpc__link{
                    position: absolute;
                    margin-top: -20px;
                    padding-right: 22px;
                }

                .hpc__title2{
                    margin-bottom: 34px;
                }
            }
        }
    }

    &__title2{
        
        font-size:1.8rem;
        line-height: 1.55;
        margin-bottom: 20px;
        a{
            color:#c05423;
            &:hover, &:focus{
                text-decoration: underline;
            }
        }
    }

    &__date{
        font-size:1.4rem;
        line-height: 1.65;
        color: #333;
        font-style: italic;  
        display: block;
        margin-bottom: 5px;

        &--jobs{
            margin:-34px 0 25px;
        }
    }
    
    &__text{
        font-size:1.6rem;
        line-height: 1.65;
        color: #333;
        margin: 0 22px 17px 0; 

        a{
            color:#c05423;
            text-decoration: underline;
            &:hover, &:focus{
                text-decoration: none; 
            }
        }

        ul{ 
            margin:17px 0 26px 17px;
            li{
                background:  url(#{$layout}li.png) 0 7px no-repeat;
                @include rem(1.6); 
                line-height:1.3; 
                @extend .sans; 
                padding:0 0 0 15px; 
                margin-bottom:9px;}
        }
    }
    &__link{
        font-size:1.6rem;
        line-height: 1.65;
        color: #333;
        text-decoration: underline;
        display: block;
        margin-bottom: 10px;
        background:#FFF;
        &:hover, &:focus{
            text-decoration: none;
        }
    }
    &__btm {
        @extend %twitter_btm;
        border-top:1px solid #FFF;
        background: #FFF url(/assets/images/site/sub/side_dots.png) repeat-x 0 0;
    }

    &__btn {
        @extend %twitter_btn;
    }
}
