$layout: "/assets/images/site/layout/";
$home: "/assets/images/site/home/";
$sub: "/assets/images/site/sub/";
$font: "/assets/fonts";
$icofont:"/assets/fonts";


$base_width: 960;
$number_of_columns: 12;
$gutter_size:10;

$brand-primary: #13788a; //blue  (#44156e, #7F5C9E old colors)
//#0e4e58 primary dark
$brand-secondary: #c05423; //orange
$slide-nav: #d65e27;
//#853c1b secondary dark

$brand-tertiary: #759493; //h2 (#749500 old color)

$icon-chevron-up: "\f077";
$icon-chevron-down: "\f078";
$icon-search: "\e900";


@font-face {
    font-family: 'icomoon';
    src:    url('#{$icofont}/icomoon.eot?eda0h1');
    src:    url('#{$icofont}/icomoon.eot?eda0h1#iefix') format('embedded-opentype'),
        url('#{$icofont}/icomoon.ttf?eda0h1') format('truetype'),
        url('#{$icofont}/icomoon.woff?eda0h1') format('woff'),
        url('#{$icofont}/icomoon.svg?eda0h1#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-search {
    &:before {
        content: $icon-search;
    }
}
.icon-chevron-up{
	&:before{
		content:$icon-chevron-up;
	}
}
.icon-chevron-down{
	&:before{
		content:$icon-chevron-down;
	}
}

@mixin fonts{

/**
 * @license
 * MyFonts Webfont Build ID 3001244, 2015-03-31T14:59:24-0400
 *
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are
 * explicitly restricted from using the Licensed Webfonts(s).
 *
 * You may obtain a valid license at the URLs below.
 *
 * Webfont: TradeGothicLT-CondEighteen by Linotype
 * URL: http://www.myfonts.com/fonts/linotype/trade-gothic/condensed-no-18-63882/
 * Licensed pageviews: 250,000
 *
 * Webfont: TradeGothicLT-BoldCondTwenty by Linotype
 * URL: http://www.myfonts.com/fonts/linotype/trade-gothic/bold-condensed-no-20-63882/
 * Licensed pageviews: 500,000
 *
 *
 * License: http://www.myfonts.com/viewlicense?type=web&buildid=3001244
 * Webfonts copyright: Part of the digitally encoded machine readable outline data for producing the Typefaces provided is copyrighted (c) 1989, 1992, 2003 Linotype Library GmbH, www.linotype.com. All rights reserved. This software is the property of Linotype Library GmbH, and
 *
 * © 2015 MyFonts Inc
*/


	/* @import must be at top of file, otherwise CSS will not work */
	@import url('https://hello.myfonts.net/count/2dcb9c');


	@font-face {
		font-family: 'TradeGothicLT-CondEighteen';
		src: url('#{$font}/2DCB9C_0_0.eot');
		src:
			url('#{$font}/2DCB9C_0_0.eot?#iefix') format('embedded-opentype'),
			url('#{$font}/2DCB9C_0_0.woff2') format('woff2'),
			url('#{$font}/2DCB9C_0_0.woff') format('woff'),
			url('#{$font}/2DCB9C_0_0.ttf') format('truetype'),
			url('#{$font}/2DCB9C_0_0.svg#wf') format('svg');
		font-weight: normal;
	    font-style: normal;
	}


	@font-face {
		font-family: 'TradeGothicLT-BoldCondTwenty';
		src: url('#{$font}/2DCB9C_1_0.eot');
		src:
			url('#{$font}/2DCB9C_1_0.eot?#iefix') format('embedded-opentype'),
			url('#{$font}/2DCB9C_1_0.woff2') format('woff2'),
			url('#{$font}/2DCB9C_1_0.woff') format('woff'),
			url('#{$font}/2DCB9C_1_0.ttf') format('truetype'),
			url('#{$font}/2DCB9C_1_0.svg#wf') format('svg');
		font-weight: normal;
	    font-style: normal;
	}




	// @font-face {
	// 	font-family:"Open Sans";
	// 	src:url("#{$font}/opensans-regular-webfont.eot?#iefix");
	// 	src:
	// 		url("#{$font}/opensans-regular-webfont.eot?#iefix") format("eot"),
	// 		url("#{$font}/opensans-regular-webfont.woff2") format("woff2"),
	// 		url("#{$font}/opensans-regular-webfont.woff") format("woff"),
	// 		url("#{$font}/opensans-regular-webfont.ttf") format("truetype"),
	// 		url("#{$font}/opensans-regular-webfont.svg#opensans-regular-webfont") format("svg");
	// 	font-weight: normal;
	//     font-style: normal;
	// }

	// @font-face {
	// 	font-family:"Open Sans";
	// 	src:url("#{$font}/opensans-bold-webfont.eot?#iefix");
	// 	src:
	// 		url("#{$font}/opensans-bold-webfont.eot?#iefix") format("eot"),
	// 		url("#{$font}/opensans-bold-webfont.woff2") format("woff2"),
	// 		url("#{$font}/opensans-bold-webfont.woff") format("woff"),
	// 		url("#{$font}/opensans-bold-webfont.ttf") format("truetype"),
	// 		url("#{$font}/opensans-bold-webfont.svg#opensans-bold-webfont") format("svg");
	// 	font-weight: normal;
	//     font-style: normal;
	// }



	.headline {
		font-family: 'TradeGothicLT-CondEighteen', sans-serif;
		/* Better Font Rendering =========== */
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}
	.headline-bold {
		font-family:'TradeGothicLT-BoldCondTwenty', sans-serif;
		/* Better Font Rendering =========== */
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}
	.sans {
		font-family: "omnes-pro", sans-serif;
		/* Better Font Rendering =========== */
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}
	.sans-bold {
		font-family: "omnes-pro", sans-serif; font-weight: 500;
		/* Better Font Rendering =========== */
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}
}

@mixin borders{
	%br5{@include br(5px);}
}
