#slider_wrap{
 height:389px; padding:5px 10px 14px; margin:6px auto 20px;  background-color: #fff; overflow:hidden;
  @include media-breakpoint-up(lg) {
	width:940px;
  }
	@include box-shadow(0 0 5px rgba(0,0,0,.32));

	ul{margin:0; height: 100%;
		li{height: 100%; background:none; padding:0; margin:0; line-height:1; position:relative;}
	}
	.bx-wrapper {
	  @include media-breakpoint-up(lg) {
		width: 920px;
	  }
		.bx-viewport{border:none; left:auto; @include box-shadow(none); height: 100% !important;}
	}
	.content{
	   height:300px; position:relative; right:60px; top:50px;
	  @include media-breakpoint-up(lg) {
		width:330px;
		position:absolute;
	  }
	}
	h2{color: $brand-secondary; @extend .headline; @include rem(3.2); line-height:1.1; text-transform:uppercase; margin-bottom:19px;
		a{color:$brand-secondary;}
	}
	p{color:#333; @include rem(1.4); line-height:1.4; margin-bottom:20px;}
	a.more, span.more{
		display:inline-block; margin-top:7px; border: 1px solid $brand-secondary;background-color: $brand-secondary; @include br(6px); color:#FFF; text-transform:uppercase; @include rem(2);@extend .headline; padding:8px 18px;
	}
	a.block{
	  display:block;

	  img {
		display: block;
	  }
	}
	.bx-controls-direction a{background:  url(#{$home}slide_show_arrows.png) 0 0 no-repeat; width:32px; height:55px; margin-top:-28.5px;}
	a.bx-prev{background-position: 0 100%; left:0;
		&:hover, &:focus{background-position: 0 0;}
	}
	a.bx-next{background-position: 100% 100%; right:0;
		&:hover, &:focus{background-position: 100% 0;}
	}
	.bx-wrapper .bx-pager, .bx-wrapper .bx-controls-auto{bottom:11px;}
	.bx-wrapper .bx-pager.bx-default-pager a{@include br(0); background:  url(#{$home}slide_show_buttons.png) 100% 0 no-repeat; width:21px; height:22px; margin:0 4px;
		&.active, &:hover, &:focus{background-position: -1px 0;}
	}
	.bx-wrapper .bx-pager{padding-top:0; margin-top:20px;}
}

.home{
	.half{
		//background-color: #F8F9FA; 
		background:#FFF;
	  margin: 10px 0;

	  @include media-breakpoint-up(lg) {
		width:460px;
		float:left;
		margin:0 10px;
	  }
		&:last-child{margin-right:0;}
		h2{@extend .headline; background-color: $brand-primary; border-bottom:3px solid #ECEEF1; color:#FFF; @include rem(2.2); text-transform:uppercase; padding:11px 21px 9px;}
		.content{padding:20px;}


	}

	.third{
	  display:inline-block; margin:14px 11px; vertical-align:top;
	  @include media-breakpoint-up(lg) {
		width:32%;
	  }
			&:last-child{margin-right:-25px;}
			&.last{margin-right:-25px;}

		span.image_wrap{background: url(#{$layout}img_bg.png); padding:5px; display:inline-block;
			img{padding:none; background:#ECEEF1; padding:1px; display:block;}
		}

		h2{@include rem(3.2); text-transform:uppercase; @extend .headline; margin:25px 0 10px;

		}
		a{
			h2{color:$brand-secondary; }
		}
	}

	.half, .third{
		p{@include rem(1.3); line-height:1.65; color:#333; margin:0 22px 17px 0;}
		a{color:$brand-secondary;
			&:hover, &:focus{text-decoration:underline;}
			&.more{text-decoration:underline;background:url(#{$home}external.png) no-repeat 100% 6px; padding-right:19px; @include rem(1.3);
				&:hover, &:focus{text-decoration:none;}
			}
		}
	}
}


