//@import "shortcuts_local.scss";
//@import "site.scss";
@include reset;
@include fonts;
html {
    font-size: 62.5%;
}

.new-bg {
    background: #ECEEF1;

    @include media-breakpoint-up(lg) {
        background: #ECEEF1 url(#{$layout}bg.png) repeat-x;
    }
}

body {
    @extend .sans;

    min-width: 300px;

    overflow-x: hidden;

    &.locked {
        overflow-y: hidden;
    }
}

strong,
b {
    @extend .sans-bold;
}

i,
em {
    font-style: italic;
}

div.print-wrap {
    @extend .clear_fix;
}

.print-wrap__fr {
    float: right;
}

a.mopadlock {
    background: url(#{$layout}lock4.png) no-repeat;
    background-size: 1.5rem;
    background-position: right;
}

button.print,
a.share-fb,
a.share-tw {
    background:transparent url(#{$layout}print.svg) no-repeat;
    background-size: 100%;
    width: 24px;
    height: 26px;
    margin-bottom: 10px;
    display: inline-block;
    border: none;
    text-indent: -9999px;
    cursor: pointer;
    opacity: .6;
    vertical-align: middle;
    transition: all .35s;
    margin-left: 10px;
    &:hover,
    &:focus {
        opacity: 1;
    }
}

button.print {
    position: relative;
    top: 1px;
}

a.share-fb {
    background-image: url(#{$layout}facebook-square.svg);
}

a.share-tw {
    background-image: url(#{$layout}twitter-square.svg);
}

#top {
    margin: auto;
    position: relative;
    @extend .clear_fix;
    margin-bottom: 24px;
    padding-right: 1.5rem;
    padding-left: 1.5rem;
    display: flex;
    flex-direction: column;

    @include media-breakpoint-up(lg) {
        width: 960px;
        padding: 0;
        display: block;
    }
}

.mobile_menu {
    width: 40px;
    height: 40px;
    margin-top: 24px;
    margin-right: 1.5rem;
    border: 4px solid #c05423;

    @include media-breakpoint-up(lg) {
        display: none;
    }
}

.home_link {
    padding-top: 60px;
}

.mobile_menu:after,
.mobile_menu:before,
.mobile_menu div {
    background-color: #13788a;
    border-radius: 3px;
    content: '';
    display: block;
    width: 28px;
    height: 4px;
    margin: 5px 2px;
}

.site_menu {
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;
    background: $brand-primary;
    padding: 50px 1.5rem;
    display: none;
    overflow-y: auto;
    padding-bottom: 200px;

    .locked & {
        display: block;

        @include media-breakpoint-up(lg) {
            display: none;
        }
    }
}

.secondary_mobile {

    a {
        color: #fff;
        @include rem(1.6);
        @extend .sans;
        margin: 10px 0;
        display: block;
    }
}

.main_nav_mobile {
    margin-bottom: 55px;
    padding-top: 30px;

    a {
        @extend .headline-bold;
        @include rem(2.3);
        margin: 20px 0;
        color: #fff;
        display: block;
        text-transform: uppercase;
    }
}

.site_close {
    height: 45px;
    width: 45px;
    position: absolute;
    right: 15px;
    top: 15px;

    &:after {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        content: "\274c";
        font-size: 25px;
        background: #fff;
        line-height: 38px;
        text-align: center;
        border: 4px solid $brand-secondary;
    }
}

.main_header {
    height: 100px;
    background: #fff;
    display: flex;
    border-top: 10px solid #13788a;
    margin-left: -1.5rem;
    margin-right: -1.5rem;
    justify-content: space-between;

    @include media-breakpoint-up(lg) {
        height: 162px;
        background: transparent;
        display: block;
        border: none;
        margin: 0;
    }

    h1 {
        margin-top: 26px;
        padding-left: 1.5rem;

        @include media-breakpoint-up(lg) {
            margin: 0;
            padding: 0;
        }
    }

    h1 a {
        width: 200px;
        height: 34px;
        display: block;

        text-indent: -9999px;
        background: url(#{$layout}logo-mobile.png) 0 0 no-repeat;

        @include media-breakpoint-up(lg) {
            width: 660px;
            height: 69px;
            left: 35px;
            background: url(#{$layout}main_sprite.png) 0 0 no-repeat;
            padding: 0;
            position: relative;
            top: 67px;
        }
    }
    .secondary {
        display: none;

        @include media-breakpoint-up(lg) {
            display: block;
        }

        position: absolute;
        top: 0;
        right: 10px;
        width: auto;
        height: 38px;
        text-align: right;
        border-right: 1px solid rgba(#fff, .3);
        &:before {
            content: "";
            width: 1px;
            height: 100%;
            position: absolute;
            top: 0;
            right: 0px;
            background: #a26e15;
            z-index: 1;
        }
        &__item {
            display: inline-block;
            vertical-align: top;
            margin-left: -4px;
        }
        &__link {
            background: $brand-secondary;
            text-align: left;
            height: 38px;
            display: block;
            @include rem(1.4);
            color: #FFF;
            @extend .sans;
            position: relative;
            border-left: 1px solid rgba(#fff, .3);
            box-sizing: border-box;
            padding: 12px 20px;
            &:hover,
            &:focus {
                background: darken($brand-secondary, 10%);
            }
            &:before {
                content: "";
                width: 1px;
                height: 100%;
                position: absolute;
                top: 0;
                left: -2px;
                background: #a26e15;
            }
            &--search {
                padding: 8px 20px;
                .icon-search {
                    @include rem(2);
                }
            }
        }
    }
}

.welcome {
    color: #fff;
    @include rem(1.4);
    @extend .sans;
    margin: 10px;
    display: inline-block;
    font-weight: bold;

    @include media-breakpoint-up(lg) {
        width: 100%;
        position: absolute;
        top: 55px;
        right: 0px;
        @include rem(1.6);
        font-weight: bold;
        color: $brand-primary;
        display: inline-block;
        text-align: right;
    }
    a {
        color: #fff;
        @include rem(1.4);
        @extend .sans;
        display: inline-block;
        font-weight: bold;

        @include media-breakpoint-up(lg) {
            background: none;
            color: $brand-secondary;
            padding: 0 4px 0 0;
            margin: 0;
            display: inline-block;
            &:before {
                display: none;
            }
        }
    }
}

.the_drop_down {
    display: none;
    width: 445px;
    background-color: #ebedf1;
    position: absolute;
    padding: 5px;
    right: 0;
    @include br(0 0 5px 5px);
    .site_menu & {
        width: 100%;
        display: block;
        background: transparent;
    }
    input {
        &[type="text"],
        &[type="password"] {
            @extend %br5;
            background: #FFF;
            color: #333;
            @include rem(1.2);
            border: none;
            margin: 0 5px;
            width: 275px;
            padding: 12px 12px 13px 11px;
            float: left;

            .site_menu & {
                padding: 11px 12px 11px 11px;
            }
        }
        &[type="submit"] {
            @extend %br5;
        ;
            float: right;
            margin: 5px 10px 0 0;
            cursor: pointer;
        }
        &[type="password"] {
            margin: 5px;
        }
    }
    a {
        background: none;
        display: block;
        width: auto;
        height: auto;
        margin: 10px 0 5px 5px;
        @include rem(1.2);
        color: $brand-secondary;
        clear: both;
    }
    .search-form {
        input[type="text"] {
            width: 260px;
            .site_menu & {
                width: 190px;
            }
        }
        input[type="submit"] {
            margin-top: 0;
        }
    }
}


#nav-sub {
    @include media-breakpoint-up(lg) {
        margin: 0 -1px;
    }
}

.main_nav {
    display: none;

    @include media-breakpoint-up(lg) {
        display: block;
    }

    text-align: center;

    li {
        display: inline-block;
        border-right: 1px solid #DADEE3;
        height: 57px;
        &.here,
        &.parent-here {
            a {
                color: darken($brand-secondary, 13.7%);
            }
        }
        &.last {
            border-right: none
        }
    }
    a {
        @extend .headline-bold;
        @include rem(2.3);
        line-height: 1;
        padding: 17px 32px;
        color: $brand-secondary;
        display: block;
        text-transform: uppercase;
        &:hover,
        &:focus {
            color: darken($brand-secondary, 13.7%);
        }
    }
}

.main_footer {
    clear: both;
    h2 {
        @extend .headline-bold;
        @include rem(2.2);
        text-transform: uppercase;
        text-align: center;
        padding: 10px 0;
        color: #999;
    }
    .top {
        border-bottom: 1px solid #E0E0E0;
        padding-top: 10px;
        background-color: #fff;
    }
    .contain {
        margin: auto;

        @include media-breakpoint-up(lg) {
            width: 940px;
        }
    }
    a.bx-prev,
    a.bx-next {
        width: 24px;
        height: 45px;
        border: none;
        text-indent: -9999px;
        display: inline-block;
        position: absolute;
        cursor: pointer;
    }
    a.bx-prev {
        left: -15px;

        @include media-breakpoint-up(lg) {
            left: auto;
        }
    }
    a.bx-next {
        right: -15px;

        @include media-breakpoint-up(lg) {
            right: auto;
        }
    }

    a.bx-prev,
    a.bx-prev:hover,
    a.bx-prev:focus {
        background: url(#{$layout}left_arrow.png) 0 0 no-repeat;

        @include media-breakpoint-up(lg) {
            left: -59px;
        }
    }
    a.bx-next,
    a.bx-next:hover,
    a.bx-next:focus {
        background: url(#{$layout}right_arrow.png) 0 0 no-repeat;

        @include media-breakpoint-up(lg) {
            right: -61px;
        }
    }
    #slider {
        position: relative;
        .bx-wrapper .bx-viewport {
            @include box-shadow(none);
            left: auto;
        }
    }
    #slide_hide {
        margin: auto;
    }
    #slide_show {
        text-align: center;
        a {
            width: 131px;
            height: 111px;
            display: inline-block;
            vertical-align: middle;
            text-align: center;
            display: table;
            /* margin:0 7px; display:none;*/
            span.image_wrap {
                display: table-cell;
                vertical-align: middle;
            }
            span.image_wrap2 {
                display: block;
                text-align: center;
            }
            img {
                max-width: 120px;
                max-height: 66px;
                margin: auto;
            }
            &.showing {
                display: inline-block !important;
                img {
                    display: inline-block;
                }
            }
        }
    }
    .bx-wrapper .bx-pager {
        display: none;
    }
    .col1,
    .col2,
    .col3 {
        display: inline-block;
        vertical-align: top;
        margin-top: 20px;

        @include media-breakpoint-up(lg) {
            height: 159px;
            background:url(#{$layout}footer_divide.png) no-repeat 100% 10px;
        }
    }
    .col1 {
        width: 100%;

        @include media-breakpoint-up(lg) {
            width: 370px;
            padding-right: 47px;
        }
    }
    .col2 {
        width: 100%;

        @include media-breakpoint-up(lg) {
            width: 297px;
            padding-left: 42px;
        }
    }
    .col3 {
        width: 100%;
        background: none;
        margin-right: -1px;

        @include media-breakpoint-up(lg) {
            width: 267px;
            padding-left: 42px;
        }
        a {
            display: inline-block;
            overflow: hidden;
            line-height: 30;
            width: 24px;
            height: 24px;
            margin-right: 20px;
            background: url(#{$layout}footer_social.png) 0 0 no-repeat;
            &.linked {
                background-position: 0 0;
            }
            &.twitter {
                background-position: -48px 0;
            }
            &.youtube {
                background-position: -97px 0;
            }
            &.email {
                background-position: 100% 0;
                margin-right: 0
            }
            &.facebook {
                background:url(#{$layout}fb.png) no-repeat;
                width: 25px;
            }
        }
    }
    p {
        color: #666;
        @include rem(1.4);
        line-height: 1.4;
        &.title {
            color: #999;
            @include rem(2.5);
            margin-bottom: 10px;
            @extend .headline-bold;
            text-transform: uppercase;
            line-height: 1;
            margin-bottom: 14px;
        }
        &.phone {
            color: #999;
            @include rem(2.2);
            @extend .headline-bold;
            line-height: 1;
            margin-top: 10px;
            a {
                line-height: 1;
                color: #999;
                width: 100%;
                background: none;
            }
        }
    }
    li {
        margin-bottom: 7px;
        @include rem(1.6);
        a {
            color: $brand-secondary;
            &:hover,
            &:focus {
                text-decoration: underline;
            }
        }
    }
    .copyright {
        background-color: #ECEEF1;
        padding: 16px 0;
        p {
            color: #999;
            @include rem(1.3);
        }
        .credit {
            float: right;
        }
        a {
            color: #999;
            &:hover,
            &:focus {
                color: $brand-primary;
                text-decoration: underline;
            }
        }
    }
}

.main_footer {
    clear: both;
    h2 {
        @extend .headline-bold;
        @include rem(2.2);
        text-transform: uppercase;
        text-align: center;
        padding: 10px 0;
        color: #999;
    }
    .top {
        border-bottom: 1px solid #E0E0E0;
        padding-top: 10px;
        background-color: #fff;
    }
    .contain {
        margin: auto;
        padding-right: 1.5rem;
        padding-left: 1.5rem;

        @include media-breakpoint-up(lg) {
            width: 940px;
            padding: 0;
        }
    }
    a.bx-prev,
    a.bx-next {
        width: 24px;
        height: 45px;
        border: none;
        text-indent: -9999px;
        display: inline-block;
        position: absolute;
        cursor: pointer;
    }
    a.bx-prev,
    a.bx-prev:hover,
    a.bx-prev:focus {
        background: url(#{$layout}left_arrow.png) 0 0 no-repeat;

        @include media-breakpoint-up(lg) {
            left: -59px;
        }
    }
    a.bx-next,
    a.bx-next:hover,
    a.bx-next:focus {
        background: url(#{$layout}right_arrow.png) 0 0 no-repeat;

        @include media-breakpoint-up(lg) {
            right: -61px;
        }
    }
    #slider {
        position: relative;
        .bx-wrapper .bx-viewport {
            @include box-shadow(none);
            left: auto;
        }
    }
    #slide_hide {
        margin: auto;
    }
    #slide_show {
        text-align: center;
        a {
            width: 131px;
            height: 111px;
            display: inline-block;
            vertical-align: middle;
            text-align: center;
            display: table;
            /* margin:0 7px; display:none;*/
            span.image_wrap {
                display: table-cell;
                vertical-align: middle;
            }
            span.image_wrap2 {
                display: block;
                text-align: center;
            }
            img {
                max-width: 150px;
                max-height: 66px;
                margin: auto;
                padding: 0 20px;

                @include media-breakpoint-up(lg) {
                    max-width: 120px;
                    padding: 0;
                }
            }
            &.showing {
                display: inline-block !important;
                img {
                    display: inline-block;
                }
            }
        }
    }
    .bx-wrapper .bx-pager {
        display: none;
    }
    .col1,
    .col2,
    .col3 {
        display: inline-block;
        vertical-align: top;
        margin-top: 20px;

        @include media-breakpoint-up(lg) {
            height: 159px;
            background:url(#{$layout}footer_divide.png) no-repeat 100% 10px;
        }
    }
    .col1 {
        @include media-breakpoint-up(lg) {
            width: 371px;
            padding-right: 47px;
        }
    }
    .col2 {
        @include media-breakpoint-up(lg) {
            width: 297px;
            padding-left: 42px;
        }
    }
    .col3 {
        @include media-breakpoint-up(lg) {
            width: 267px;
            padding-left: 42px;
        }
        background: none;
        a {
            display: inline-block;
            overflow: hidden;
            line-height: 30;
            width: 24px;
            height: 24px;
            margin-right: 20px;
            background: url(#{$layout}footer_social.png) 0 0 no-repeat;
            &.linked {
                background-position: 0 0;
            }
            &.twitter {
                background-position: -48px 0;
            }
            &.youtube {
                background-position: -97px 0;
            }
            &.email {
                background-position: 100% 0;
                margin-right: 0
            }
            &.facebook {
                background:url(#{$layout}fb.png) no-repeat;
                width: 25px;
            }
        }
    }
    p {
        color: #666;
        @include rem(1.4);
        line-height: 1.4;
        &.title {
            color: #999;
            @include rem(2.5);
            margin-bottom: 10px;
            @extend .headline-bold;
            text-transform: uppercase;
            line-height: 1;
            margin-bottom: 14px;
        }
        &.phone {
            color: #999;
            @include rem(2.2);
            @extend .headline-bold;
            line-height: 1;
            margin-top: 10px;
        }
    }
    li {
        margin-bottom: 7px;
        @include rem(1.6);
        a {
            color: $brand-secondary;
            &:hover,
            &:focus {
                text-decoration: underline;
            }
        }
    }
    .copyright {
        background-color: #ECEEF1;
        padding: 16px 0;
        p {
            color: #999;
            @include rem(1.3);
        }
        .credit {
            float: right;
        }
        a {
            color: #999;
            &:hover,
            &:focus {
                color: $brand-primary;
                text-decoration: underline;
            }
        }
    }
}


.button,
a.button {
    padding: 5px 26px 4px;
    margin-right: 22px;
    border: 1px solid darken($brand-secondary, 13.7%);
    background-color: $brand-secondary;
    @include br(6px);
    @include rem(2.5);
    line-height: 1;
    color: #FFF;
    @extend .headline-bold;
    display: inline-block;
    vertical-align: middle;
    text-transform: uppercase;
    &:hover,
    &:focus {
        background-color: $brand-secondary;
        background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEwMCAxMDAiIHByZXNlcnZlQXNwZWN0UmF0aW89Im5vbmUiPjxsaW5lYXJHcmFkaWVudCBpZD0iaGF0MCIgZ3JhZGllbnRVbml0cz0ib2JqZWN0Qm91bmRpbmdCb3giIHgxPSI1MCUiIHkxPSIxMDAlIiB4Mj0iNTAlIiB5Mj0iLTEuNDIxMDg1NDcxNTIwMmUtMTQlIj4KPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzQxMTk3MSIgc3RvcC1vcGFjaXR5PSIxIi8+CjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzVkMWZhNSIgc3RvcC1vcGFjaXR5PSIwIi8+CiAgIDwvbGluZWFyR3JhZGllbnQ+Cgo8cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMTAwIiBoZWlnaHQ9IjEwMCIgZmlsbD0idXJsKCNoYXQwKSIgLz4KPC9zdmc+);
        background-image: -moz-linear-gradient(top, $brand-secondary 0%, darken($brand-secondary, 10%) 100%);
        background-image: -o-linear-gradient(top, $brand-secondary 0%, darken($brand-secondary, 10%) 100%);
        background-image: -webkit-linear-gradient(top, $brand-secondary 0%, darken($brand-secondary, 10%) 100%);
        background-image: linear-gradient(top, $brand-secondary 0%, darken($brand-secondary, 10%) 100%);
        @include box-shadow(inset 2px 2px 4px rgba(255, 255, 255, 0.5));
        text-decoration: none;
    }
    &:active {
        background-color: $brand-secondary;
        @include box-shadow(inset 2px 3px 5px darken($brand-secondary, 13.7%));
    }
}

a.button {
    padding: 8px 26px 6px;
    color: #FFF !important;
}

@import "sub.scss";
@import "home";
@import "reskin.scss";
@import "twitter.scss";
@include borders;
@include clearfix;
.lt-ie9 {}

.lt-ie8 {
    .home {
        .half:first-child+.half {
            margin-right: 0;
        }
    }

}

html.CSS1Compat {
    background: none;
}

#content.main,
#content.side_col {
    float: none;
    padding: 10px;
    width: auto;
    min-height: 1px;
    background: none;
}

body.cke_panel_frame {
    background: none;
    font-size: 13px;
    color: #000;
}

html.cke_skin_wygwam2_container {
    background: none;
}

table.reset,
table.reset * {
    border: none !important;
    background: none !important;
    border-collapse: collapse;
    border-spacing: 0;
}


#content.wygwam.main strong {
    font-weight: bold;
}

// hotfix
.main table span.image_wrap img {
    background: none
}

.contain img {
    max-width: 100%;
    height: auto;
}

.alert-banner {
    &__link {
        display: block;
        background: #BC2F14;
        color: white;
        font-size: 36px;
        padding: 35px 0;
        transition: all .25s;
        text-align: center;
        &:hover,
        &:focus {
            background: darken(#BC2F14, 10%);
            .alert-banner__text {
                border-bottom: 1px solid #FFF;
            }
        }
    }
    &__img {
        max-width: 150px !important;
        margin-right: 10px;
        display: inline-block;
        vertical-align: middle;
    }
    &__text {
        display: inline-block;
        vertical-align: middle;
        border-bottom: 1px solid transparent;
        text-align: left;
        line-height: 1.2;
        strong,
        b {
            font-weight: bold;
        }
    }
    .contain {
        display: block;
        margin: auto;

        @include media-breakpoint-up(lg) {
            width: 940px;
        }
    }
}

.secondary__item.logged_in {
    display: none;
}

.news-search {
	margin: 4px 0 10px 0;
}

.news-button {
	font-size: 2em;
	padding: 5px 10px 4px;
}

.news-input {
	border: 1px solid #999;
	padding: 4px 0 4px 4px;
}

.a-grid {
	display: flex;
	flex-wrap: wrap;
    margin-left: -32px;
}

.a-grid__col {
	flex: 0 1 auto;
	width: 100%;
	position: relative;
    padding-left: 32px;
    margin-bottom: 48px;
    box-sizing: border-box;
}

@media screen and (min-width: 575px) {
    .a-grid__col {
        width: 50%;
    }
}

.has-a-grid .side_col {
    display: none;
}